<template>
  <div></div>
</template>
<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';

export default Vue.extend({
  computed: {
    channels: {
      get() {
        return this.$store.state.channels.channels;
      },
      set(value) {
        this.$store.commit('channels/setChannels', value);
      }
    },
    channel: {
      get() {
        return this.$store.state.channels.channel;
      },
      set(value) {
        this.$store.commit('channels/setChannel', value);
      }
    }
  },
  methods: {
    async getChannels() {
      const this_ = this;
      await apiClient
        .get('api/channels', {})
        .then((response) => {
          if (Array.isArray(response.data)) {
            this_.$store.commit('channels/setChannels', response.data);
            this_.channels = response.data;
          }
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    getChannel(id) {
      this.$store.commit('channels/setPlayChannel', true);
      this.$store.commit('playlists/setPlayPlaylist', false);
      this.$store.commit('playlists/setPlaylist', {});
      if (this.channels.length > 0) {
        const channel = this.channels.find(
          (f) => f.id.toString() === id.toString()
        );
        if (typeof channel === 'object') {
          // this.images = [channel.image, this.test_image];
          this.$store.commit('channels/setChannel', channel);
        }

      } else {
        this.getChannels();
      }
    },
  },
  async created() {
    await this.getChannels();
    this.getChannel(this.$route.params.id);

    this.$router.push({ name: 'channels' });
  }
});
</script>
<style lang="scss">
</style>
